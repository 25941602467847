import React from 'react';
import '../styles/Contact.css';

function Contact() {
    return (
        <section className="contact">
            <h2>Contact</h2>
            <p>
            <a href="mailto:syokoyama@triadgoodfellows.com" className="email-link">
                    <span className="email-address">syokoyama<span className="pop">@</span>triadgoodfellows<span className="pop">.</span>com</span>
                </a>
            </p>
        </section>
    );
}

export default Contact;