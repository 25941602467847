import React from 'react';
import '../styles/SNSLinks.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook } from '@fortawesome/free-brands-svg-icons';

function SNSLinks() {
    return (
        <section className="sns-links">
            <h2>SNS</h2>
            <div className="card-container">
                <div className="sns-card large-card">
                    <a href="https://www.linkedin.com/in/yokosugc/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faLinkedin} size="3x" />
                    </a>
                </div>
                <div className="sns-card large-card">
                    <a href="https://www.facebook.com/zenmaiwork/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faFacebook} size="3x" />
                    </a>
                </div>
            </div>
        </section>
    );
}

export default SNSLinks;