import React from 'react';
import '../styles/CloudAnimation.css';

function CloudAnimation() {
    return (
        <div className="cloud-animation">
            <div className="cloud"></div>
            <div className="cloud"></div>
            <div className="cloud"></div>
        </div>
    );
}

export default CloudAnimation;