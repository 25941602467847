import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Home.css';

function Home() {
    const [season, setSeason] = useState('');

    useEffect(() => {
        const month = new Date().getMonth() + 1; // 月を取得 (1-12)
        if (month === 3 || month === 4) {
            setSeason('sakura');
        } else if (month === 5 || month === 6) {
            setSeason('tsuyu');
        } else if (month >= 7 && month <= 9) {
            setSeason('summer');
        } else if (month === 10 || month === 11) {
            setSeason('leaves');
        } else {
            setSeason('snow');
        }
    }, []);

    const getSeasonMessage = (season: string) => {
        switch (season) {
            case 'sakura':
                return 'Cherry blossoms bloom, cherry blossoms fall, a new world!';
            case 'tsuyu':
                return 'Go to Summer';
            case 'summer':
                return 'Endless Summer!!!';
            case 'leaves':
                return 'Autumn is delicious!!!';
            case 'snow':
                return 'Training! Training! Training!';
            default:
                return '';
        }
    };

    return (
        <section className="home">
            <h1 className="home-title">Suguru Yokoyama</h1>
            {season && <p className="season-message emphasized">{getSeasonMessage(season)}</p>}
            <SeasonAnimation season={season} />
            <div className="card-container">
                <Link to="/about" className="card" data-tooltip="Learn more about Suguru">About</Link>
                <Link to="/sns" className="card" data-tooltip="Follow Suguru on social media">SNS</Link>
                <Link to="/contact" className="card" data-tooltip="Get in touch with Suguru">Contact</Link>
            </div>
        </section>
    );
}

interface SeasonAnimationProps {
    season: string;
}

const SeasonAnimation: React.FC<SeasonAnimationProps> = ({ season }) => {
    return (
        <div className={`season-animation ${season} flashy-animation`}>
            {/* Enhanced animation elements */}
            {season === 'sakura' && <Sakura />}
            {season === 'tsuyu' && <Tsuyu />}
            {season === 'summer' && <Summer />}
            {season === 'leaves' && <Leaves />}
            {season === 'snow' && <Snow />}
        </div>
    );
};

const Sakura: React.FC = () => (
    <div className="sakura-blizzard">
        {/* Enhanced sakura petals animation */}
        <span className="petal">🌸</span>
        <span className="petal">🌸</span>
        <span className="petal">🌸</span>
        <span className="petal">🌸</span>
        <span className="petal">🌸</span>
    </div>
);

const Tsuyu: React.FC = () => (
    <div className="tsuyu-sky">
        {/* Enhanced rain and sun animation */}
        <span className="raindrop">☔️</span>
        <span className="sun">☀️</span>
        <span className="raindrop">☔️</span>
        <span className="sun">☀️</span>
    </div>
);

const Summer: React.FC = () => (
    <div className="summer-sun">
        {/* Earth orbiting the Sun animation */}
        <div className="sun">☀️</div>
        <div className="earth-orbit">
            <div className="earth">🌍</div>
        </div>
    </div>
);

const Leaves: React.FC = () => (
    <div className="leaves-fallen">
        {/* Enhanced fallen leaves animation */}
        <span className="leaf">🍂</span>
        <span className="leaf">🍁</span>
        <span className="leaf">🍂</span>
        <span className="leaf">🍁</span>
    </div>
);

const Snow: React.FC = () => (
    <div className="snow-fall">
        {/* Enhanced snow animation */}
        <span className="snowflake">❄️</span>
        <span className="snowflake">❄️</span>
        <span className="snowflake">❄️</span>
        <span className="snowflake">❄️</span>
    </div>
);

export default Home;