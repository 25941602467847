import React from 'react';
import '../styles/About.css';
import CloudAnimation from './CloudAnimation'; // 新しいアニメーションコンポーネントをインポート

function About() {
    return (
        <section className="about">
            <h2>About</h2>
            <p>I'm Suguru Yokoyama from Nagano.</p>
            <p>Currently CTO of BitoB and co-representative of Triad Good Fellows. Previously worked at Amazon Web Services Japan, TOSYS (Livestyle), and Tsukaeru.net.</p>
            {/* 追加のコンテンツをここに挿入 */}
            <CloudAnimation /> {/* ポップなアニメーションを追加 */}
        </section>
    );
}

export default About;