import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import SNSLinks from './components/SNSLinks';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './styles/main.css';
import ReactGA from 'react-ga';

const usePageTracking = () => {
    const location = useLocation();
  
    useEffect(() => {
      ReactGA.pageview(location.pathname + location.search);
    }, [location]);
};

function App() {
    return (
        <Router>
            <PageTracker />
            <Header />
            <main>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/sns" element={<SNSLinks />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/contact" element={<Contact />} />
                </Routes>
            </main>
            <Footer />
        </Router>
    );
}

const PageTracker = () => {
    usePageTracking();
    return null;
};

export default App;